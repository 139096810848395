.form {
    width: 100%;
    margin: 0 30px;
    padding: 15px 0;
    border: 1px solid #bbb7b7;
    border-radius: 5px;
    box-shadow: 15px 15px 30px rgba(0,0,0, .15);
}
.input {
    border: 1px solid #bbb7b7;
    border-radius: 5px;
    width: 95%;
    margin: 15px auto 0;
    padding: 10px;
    font-size: 20px;
}

.remove-btn {
    margin-right: 0.5rem;
    background-color: red;
    color: red;
  }

.bnt__block{
    display: flex;
    align-items: center;
}
  
  .item-enter {
    opacity: 0;
  }
  .item-enter-active {
    opacity: 1;
    transition: all 500ms ease-in;
  }
  .item-enter-done {
      opacity: 0;
      visibility: hidden;
  }
  .item-exit {
    opacity: 1;
  }
  .item-exit-active {
    opacity: 0;
    transition: all 500ms ease-in;
  }

.list-group-item {
    padding: 15px 20px;
    border: 1px solid #bbb7b7;
    border-radius: 5px;
    list-style: none;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    box-shadow: 15px 15px 30px rgba(0,0,0, .15);
}

.list-group-item-label {
    text-align: left;
}

.check {
    height: 30px;
    width: 30px;
    vertical-align: middle;
}

.fas {
    background-color: #ffffff;
    font-size: 25px;
    vertical-align: middle;
    border: none;
}

.btn-sm {
    background-color: #ffffff;
}

.list-group-item span {
    line-height: 35px;
    font-size: 20px;
    cursor: pointer;
    min-width: 550px;
}

.list-group-item input {
    line-height: 35px;
    font-size: 20px;
    text-align: center;
    border: 0;
}

.list-group-item button {
    width: 35px;
    height: 35px;
    margin: 3px;
    font-size: 17px;
    border: none;
    cursor: pointer;
}

.list-group-item .btn-cookie {
    color: #e09f3e;
}

.list-group-item .btn-trash {
    color: #e5383b;
}

.list-group-item .fa-star {
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    font-size: 16px;
    color: #FFD700;
    transition: 0.3s all;
    transform: translateX(30px);
    opacity: 0;
}

.list-group-item.like .fa-star {
    opacity: 1;
    transform: translateX(0px);
}

.list-group-item.increase .list-group-item-label, .list-group-item.increase .list-group-item-input {
    color: #e09f3e;
}
  
.list-group-item.increase .btn-star {
    color: #ffffff;
}